import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/main.scss"
import Navbar from "./Navbar";
import Infobar from "./Navbar/infobar";
import Footer from "./Footer";
import FullBackground from "./Common/FullBackground";
import ContactsForm from "./ContactsInfo/ContactsForm";

import{ init } from 'emailjs-com';
init("user_T5LHaoyuSngMArXjaWSgc");

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <FullBackground>
    <div className="main-body">
      <Infobar />
      <div className="container">
        <Navbar siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
    <div className="modal fade" id="Modal" tabIndex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content glass-panel">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalLabel">Заповніть форму</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <ContactsForm />
          </div>
          {/*<div className="modal-footer">*/}
          {/*  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>*/}
          {/*  <button type="button" className="btn btn-primary">Save changes</button>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
    </FullBackground>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
