const tags = [
  'Лікування варикозної хвороби нижніх кінцівок',
  'Пластична хірургія',
  'Судинна хірургія',
  'Видалення новоутворень',
  'Лікування геморою',
  'Лікування врослого нігтя',
  'Контрактура Дюпюїтрена',
  'Видалення татуажу та тату',
  'Фраксельне омолодження',
  'Лазерна гінекологія'
];

export const getTagsAsString = () => tags.join(',');

export const getImageAltText = () => tags.join(',');
