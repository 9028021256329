import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

const FullBackground = ({ className, children }) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "back14.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality:20
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(desktop)
  const bgImage = convertToBgImage(image)

  return (
      <BackgroundImage
        className={className}
        {...bgImage}
        preserveStackingContext
      >
        {children}
      </BackgroundImage>
  )
}

export default FullBackground