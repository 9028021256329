import React, {useCallback, useEffect, useRef, useState} from "react";
import Block1 from '/src/assets/images/svg_icons/001-body care.svg'
import emailjs from 'emailjs-com';
import {getImageAltText} from "../../utils/common";

const ContactsForm = () => {
  const [submit, setSubmit] = useState(false)
  const [title, setTitle] = useState('') //against spam
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')
  const formRef = useRef();

  const listener = useCallback(function (event) {
    if (!formRef.current.checkValidity()) {
      event.preventDefault()
      event.stopPropagation()
    }

    formRef.current.classList.add('was-validated')
  }, [])

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.addEventListener('submit', listener, false)
    }
  });

  const sendEmail = (e) => {
    e.preventDefault();
    if (title.length > 0) return;
    emailjs.sendForm('service_ait6a2p', 'template_f53z4sc', formRef.current, 'user_T5LHaoyuSngMArXjaWSgc')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    setName('');
    setPhone('');
    setEmail('');
    setMsg('');
    setSubmit(true);
    formRef.current.removeEventListener("submit", listener, false);
  };

  return (
    <div className="contact-form">
      {submit ?
        <div className="row">
          <div className="col-12">
            <div className="submitted">
              <img src={Block1} className="submitted-icon" alt={getImageAltText()}/>
              <p>Дякуємо! <br/> Ваш запит буде оброблено найближчим часом!
              </p>
              <div className="d-flex justify-content-center">
                <button className="btn submitted-btn" data-bs-dismiss="modal" onClick={() => setSubmit(false)}>Закрити
                </button>
              </div>
            </div>
          </div>
        </div>
        : <form ref={formRef} onSubmit={sendEmail} className="needs-validation" noValidate>
          <div className="row">
            <div className="col-md-6 form-control-wrapper">
              <input id="title" type="text" placeholder="Повний заголовок" className="form-control" value={title} name="user_title"
                     onChange={(e) => setTitle(e.target.value)}/>
              <input id="name" type="text" placeholder="Повне ім'я" className="form-control" required value={name} name="user_name"
                     onChange={(e) => setName(e.target.value)}/>
              <div className="invalid-feedback">
                Введіть ваше ім'я
              </div>
            </div>
            <div className="col-md-6 form-control-wrapper">
              <input id="phone" type="text" placeholder="Телефон" className="form-control" required value={phone} name="user_phone"
                     onChange={(e) => setPhone(e.target.value)}/>
              <div className="invalid-feedback">
                Введіть ваш номер телефону
              </div>
            </div>
            <div className="col-12 form-control-wrapper">
              <input id="email" type="email" placeholder="Електронна пошта" className="form-control" value={email} name="user_email"
                     onChange={(e) => setEmail(e.target.value)}/>
              <div className="invalid-feedback">
                Введіть вашу електронну адресу
              </div>
            </div>
            <div className="col-md-12 form-control-wrapper">
              <textarea id="message" placeholder="Повідомлення" className="form-control" rows="3" required value={msg} name="message"
                        onChange={(e) => setMsg(e.target.value)}/>
              <div className="invalid-feedback">
                Введіть ваше повідомлення
              </div>
            </div>
            <div className="col-md-12">
              <div className="send">
                <button type="submit" className="btn btn-sm fancy-button bg-gradient3 pop-onhover ml-auto float-end">
                  <span>Надіслати</span></button>
              </div>
            </div>
          </div>
        </form>
      }
    </div>
  )
};

export default ContactsForm;